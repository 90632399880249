<!-- 用户编辑弹窗 -->
<template>
  <a-modal :width="900" :visible="visible" :confirm-loading="loading" :title="isUpdate ? '修改奖项' : '新建奖项'"
    :body-style="{ paddingBottom: '8px' }" @update:visible="updateVisible" @ok="save">
    <a-form ref="form" :model="form" :rules="rules" :labelCol="{ span: 2, offset: 0 }"
      :wrapper-col="{ span: 22, offset: 0 }">
      <a-row :gutter="16">
        <a-col :md="24" :sm="24" :xs="24">
          <a-form-item label="会场" name="sceneId" v-if="!sceneId">
            <a-select v-model:value="form.sceneId" show-search placeholder="选择一个会场" showArrow
              :filterOption="filterOption" @change="change" allow-clear>
              <a-select-option v-for="(item, index) in sceneList" :key="index" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="标签" name="tags">
            <a-input v-model:value="form.tags" placeholder="请输入标签" allow-clear />
          </a-form-item>
          <a-form-item label="页面地址" name="pageUrl">
            <a-select v-model:value="form.pageUrl" show-search placeholder="选择一个页面" showArrow
              :filterOption="filterOption" @change="change" allow-clear>
              <a-select-option v-for="(item, index) in pageUrlList" :key="index" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="标题" name="title">
            <a-input v-model:value="form.title" placeholder="请输入标题" style="width: 100%" allow-clear />
          </a-form-item>
          <a-form-item label="背景图片" name="bgPic">
            <a-upload list-type="picture-card" v-model:file-list="bgPicList" @preview="handlePreview" :customRequest="
              ({ file }) => uploadFile(file, 'bgPic')
            " :remove="removeFile">
              <div v-if="bgPicList.length < 1">
                <plus-outlined />
                <div class="ant-upload-text">上传</div>
              </div>
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" :bodyStyle="{ paddingTop: '50px' }"
              @cancel="previewVisible = false">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import * as activityApi from '@/api/activity/activityInfo.js'
import * as activityUserRegApi from '@/api/activity/activityUserReg.js'
import * as screenApi from '@/api/scene/screen'
import setting from '@/config/setting'
export default {
  name: 'sceneEdit',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    sceneId: Object
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 表单验证规则
      rules: {
        activityId: [
          {
            required: true,
            type: 'string',
            trigger: 'change'
          }
        ]
      },
      // 提交状态
      loading: false,
      // 上传地址
      uploadUrl: setting.uploadUrl,
      // 是否是修改
      isUpdate: false,
      // 活动列表
      activityList: [],
      // 管理人员
      managerList: [],
      bgPicList: [],
      pageUrlList: [{ value: '/screen/luck/index', label: '抽奖：模板一' }],
      previewVisible: false
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.isUpdate = true
        this.form = this.data
        if (this.form.bgPic) {
          this.bgPicList[0] = {
            uid: '-10',
            name: 'image.png',
            status: 'done',
            url: this.form.bgPic
          }
        }
      } else {
        this.form = {}
        this.isUpdate = false
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    }
  },
  mounted() {
    console.log(this.form)
    this.queryActivityList()
  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          this.form.sceneId = this.sceneId
          if (this.bgPicList && this.bgPicList.length > 0) {
            this.form.bgPic = this.bgPicList[0].url
          }
          screenApi.save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                if (!this.isUpdate) {
                  this.form = {}
                }
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => { })
    },
    // 数组转字符串方法
    arrToStr(arr) {
      if (arr && arr.length > 0) {
        const str = arr.join(',')
        return str
      }
    },
    // 字符串转数组方法
    strToArr(str) {
      if (str) {
        return str.split(',')
      }
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    /* 选择过滤查询 */
    filterOption(input, option) {
      return (
        option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    queryActivityList(activityId) {
      activityApi.all({ activityId: activityId }).then(res => {
        if (res.code === 0) {
          this.activityList = res.data.map(item => {
            const newItem = {}
            newItem.value = item.activityId + ''
            newItem.label = item.activityName
            return newItem
          })
        }
      }).catch(() => {

      })
    },
    queryManagerList(activityId) {
      activityUserRegApi.all({ activityId: activityId }).then(res => {
        if (res.code === 0) {
          this.managerList = res.data.map(item => {
            const newItem = {}
            newItem.value = item.userId + ''
            newItem.label = '(' + item.phone + ')' + item.name
            return newItem
          })
        }
      }).catch(() => {

      })
    },
    change(id) {
      this.queryManagerList(id || -1)
    },
    handlePreview(pointPhoto) {
      this.previewImage = pointPhoto.url || pointPhoto.preview
      this.previewVisible = true
    },
    handleCancel() {
      this.previewVisible = false
    },
    removeFile(file) {
      this.bgPicList = []
    },
    uploadFile(file, name) {
      const formData = new FormData()
      formData.append('file', file)
      const uid = file.uid
      const hide = this.$message.loading('上传中..', 0)
      this.$http
        .post(this.uploadUrl, formData)
        .then((res) => {
          hide()
          if (res.data.code === 0) {
            this.bgPicList = [{
              uid: uid,
              name: 'image.png',
              status: 'done',
              url: res.data.location
            }]
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
      return false
    }
  }
}
</script>

<style scoped>
</style>
